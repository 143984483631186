<template>
  <div id="app">
    <b-card
      style="max-width: 32rem"
      class="mx-auto"
      header-text-variant="white"
      header-class="header"
      header-html="<b><i>Personel Yönetim Sistemi</i></b>"
    >
      <div class="buttonBar">
        <b-button-group>
          <b-button
            class="pageButton"
            type="button"
            :variant="getVariant('/login')"
            to="/login"
          >
            Giriş
          </b-button>
          <b-button
            class="pageButton"
            type="button"
            :variant="getVariant('/register')"
            to="/register"
          >
            Yeni Kayıt
          </b-button>
          <b-button
            class="pageButton"
            type="button"
            :variant="getVariant('/password')"
            to="/password"
          >
            Şifrem
          </b-button>
        </b-button-group>
      </div>
      <b-alert variant="danger" v-if="getError" class="spacing" show
        ><b-icon-exclamation-octagon-fill class="mr-4" />{{ getError }}</b-alert
      >
      <b-alert class="spacing" show>
        Bilgilerinizi girin ve <b>Gönder</b> butonuna tıklayın.
      </b-alert>
      <b-select v-show="false" v-model="getNotification"></b-select>
      <router-view />
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LoginPage",
  data() {
    return {
      buttonState: {
        login: "/login",
        password: "/password",
        register: "/register",
      },
      show: true,
    };
  },
  computed: {
    ...mapGetters("notification", ["getError", "getNotifications"]),
    getNotification: {
      get() {
        this.getNotifications.forEach((e) => {
          this.$bvToast.toast(e.body, {
            title: e.title,
            variant: e.type,
            solid: true,
          });
        });
        this.$store.dispatch("notification/setNotifications", []);
        return this.getNotifications;
      },
      set() {
        return this.getNotifications;
      },
    },
  },

  methods: {
    getVariant(id) {
      if (id == this.$router.history.current.path) {
        return "light";
      }
      return "secondary";
    },
  },
};
</script>


<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 10rem;
  height: 100%;
  background-color: #222222;
  color: #333333;
}
.buttonBar {
  text-align: end;
  margin-top: -3.6rem;
}
.header {
  background: rgb(5, 114, 184);
  background: linear-gradient(
    0deg,
    rgba(5, 114, 184, 1) 40%,
    rgba(17, 150, 236, 1) 85%
  );
}
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:visited {
  background-color: #489cd4;
  border-color: #489cd4;
}
.pageButton {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.spacing {
  margin-top: 0.5rem;
}
</style>